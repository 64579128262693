import Image from '../../../../../components/Image'
import './index.less'

const PDExhibitionMobile = ({data}) => {
  return (
    <div className='pdem_exhibition'>
      <div className='exhibition_header'>
        <span className='exhibition_title'>
          { data?.title ? data?.title : '标题' }
        </span>
        <span className='exhibition_vice_title'>
          { data?.content }
        </span>
      </div>
      {
        (data?.pic1) &&
        <div className='exhibition_box'>
          <div className='exhibition_item' style={{ marginLeft: 0 }}>
            <Image className='exhibition_img' src={data?.pic1} alt='' />
          </div>
        </div>
      }
    </div>
  )
}
export default PDExhibitionMobile