import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setMenuIndex, setMenuList } from "../../../store/menu";
import { setToken } from "../../../store/app";
import { setUserInfo } from "../../../store/user";
import HTTP from "../../../utils/Http";
import Image from "../../Image";
import { logout } from "../../../utils/UserAction";
import "./index.less";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息
  // 菜单
  const menuIndex = useSelector((state) => state?.Menu?.menuIndex);
  const menuList = useSelector((state) => state?.Menu?.menuList); // 菜单列表
  const [loading, setLoading] = useState(false);
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (menuList.map((m) => m.link).indexOf(location.pathname) !== -1) {
      const [target] = menuList
        .filter((f) => f.link === location.pathname)
        .map((m) => m.link);
      dispatch(setMenuIndex(menuList.map((m) => m.link).indexOf(target)));
    }
  }, [location.pathname, menuList]);

  return (
    <>
      <header className="header color_header">
        {/* 左侧 */}
        <div
          className="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          {commonConfig && commonConfig.web_logo && (
            <Image className="logo_img" src={commonConfig.web_logo} alt="" />
          )}
        </div>
        {/* 右侧 */}
        <div className="right">
          {/* 菜单 */}
          <nav className="menu_list">
            {menuList &&
              menuList?.length > 0 &&
              menuList?.map((m, index) => (
                <div
                  key={"menu_list_" + index}
                  className={`menu_item ${
                    menuIndex === index ? "menu_active" : ""
                  }`}
                  onClick={() => {
                    if (location.pathname === m.link) return;
                    navigate(m?.link);
                  }}
                >
                  {m?.title}
                </div>
              ))}
          </nav>
        </div>
      </header>
      <div className="header_ccupy" />
    </>
  );
};

export default Header;
