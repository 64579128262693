import { useState, useEffect } from "react";
import { message } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Image from "../../../../../components/Image";
import moment from "moment";
import HTTP from "../../../../../utils/Http";
import "./index.less";

const NDMain = ({ category_id }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const [data, setData] = useState(); // 新闻详情
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState();
  const [sortId, setSortId] = useState(0);
  const [recommendArticle, setRecommendArticle] = useState();
  const getAddCountApi = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/addCount" + `?t=${new Date().getTime()}`,
        { id }
      );
      if (res.status === 10000) {
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getSortApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/getCategoryList" + `?t=${new Date().getTime()}`,
        {
          category_id,
        }
      );
      if (res.status === 10000) {
        setSort(res?.data);
        if (res?.data?.length > 0) {
          if (sortId === 0) {
            if (state && state?.sortId) return setSortId(state?.sortId);
            setSortId(res?.data?.[0]?.id);
          }
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getRecommendArticleApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/recommendArticle" + `?t=${new Date().getTime()}`,
        {
          category_id,
        }
      );
      if (res.status === 10000) {
        setRecommendArticle(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getData = async () => {
    // 获取新闻详情
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/newsDetail" + `?t=${new Date().getTime()}`,
        {
          id: params.id,
          categoryId: params.type,
        }
      );
      if (res.status === 10000) {
        setData(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getSortApi();
    getRecommendArticleApi();
  }, [state?.sortId]);
  useEffect(() => {
    getData();
  }, [params, state]);

  return (
    <div className="news_details_main">
      <div className="news_details_main_box">
        <aside className="">
          <section className="news_aside_sort">
            <span className="news_aside_sort_title">新闻资讯</span>
            {sort &&
              sort?.length > 0 &&
              sort?.map((m, index) => (
                <div
                  className={`news_aside_sort_item color_main_title ${
                    index === 0 ? "no_border" : ""
                  } ${
                    index === sort.map((m) => m.id).indexOf(sortId)
                      ? "active"
                      : ""
                  }`}
                  key={"news_aside_sort_item_" + index}
                  onClick={() => {
                    setSortId(m.id);
                    navigate("/news", { state: { sortId: m.id } });
                  }}
                >
                  <Image
                    src={
                      index === sort.map((m) => m.id).indexOf(sortId)
                        ? require("./assets/icon_page_a@2x.png")
                        : require("./assets/icon_page_n@2x.png")
                    }
                    alt=""
                  />
                  <span>{m?.name}</span>
                </div>
              ))}
          </section>
          {/* 推荐文章 */}
          <section className="news_aside_recommend">
            <span className="news_aside_recommend_title">推荐文章</span>
            <div className="news_aside_recommend_list">
              {recommendArticle &&
                recommendArticle.length > 0 &&
                recommendArticle?.map((m, index) => (
                  <div
                    key={"news_aside_recommend_item_" + index}
                    className={`news_aside_recommend_item ${
                      index === 0 ? "no_margin_top" : ""
                    }`}
                    onClick={async () => {
                      if (m.aid === params?.id) return;
                      setSortId(m?.article_info?.category_id);
                      await navigate(`/news/details/${m?.article_info.category_id}/${m.aid}`, {
                        state: { sortId: m?.article_info?.category_id },
                      });
                      await getAddCountApi(m.aid);
                    }}
                  >
                    <Image src={m?.article_info?.cover} alt="" />
                    <div className="news_aside_recommend_item_content color_main_title">
                      <span>{m?.article_info?.title}</span>
                      <span>
                        {moment(m?.article_info?.release_at).format(
                          "YYYY-MM-DD HH:mm"
                        )}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </section>
        </aside>
        {/* 资讯详情 */}
        <article className="">
          <div className="article_header">
            <span className="article_header_title">{data?.title}</span>
            <div className="article_header_info">
              <span>来自：{data?.author}</span>
              <span className="margin_left">浏览量：{data?.read_count}</span>
              <span className="margin_left">
                {moment(data?.release_at).fromNow()}
              </span>
            </div>
            {/* <span className='article_header_content'>{data?.summary}</span> */}
          </div>
          <div
            className="article_body"
            dangerouslySetInnerHTML={{ __html: data?.content }}
          ></div>
          <div className="article_footer">THE END</div>
        </article>
      </div>
    </div>
  );
};

export default NDMain;
