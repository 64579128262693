import Image from '../../../../../components/Image'
import './index.less'

const PDExhibition = ({data}) => {
  return (
    <div className='pd_exhibition'>
      <div className='pd_exhibition_header animate__animated animate__slideInUp'>
        <span className='pd_exhibition_title'>
          { data?.title ? data?.title : '' }
        </span>
        <span className='pd_exhibition_vice_title'>
          { data?.content }
        </span>
      </div>
      {
        (data?.pic1) &&
        <div className='pd_exhibition_box animate__animated animate__slideInUp'>
          <div className='pd_exhibition_item' style={{ marginLeft: 0 }}>
            <Image className='pd_exhibition_img' src={data?.pic1} alt='' />
          </div>
        </div>
      }
    </div>
  )
}

export default PDExhibition