import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setMenuIndex } from '../../store/menu'
import Head from '../../components/Head'
import Header from '../../components/pc/Header'
import PDSection from './components/pc/PDSection'
import HeaderMobile from '../../components/mobile/HeaderMobile'
import PDSectionMobile from './components/mobile/PDSectionMobile'
import { flexibleWidth } from '../../utils/flexible'
import './index.less'

const ProductDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const location = useLocation()
  const commonConfig = useSelector(state => state?.App?.commonConfig)
  const menuList = useSelector((state) => state?.Menu?.menuList)
  console.log(menuList)
  const bg = menuList.filter(f=>f.pageRoute === 'Product').map(m => m.backgroud)[menuList.filter(f=>f.pageRoute === 'Product').map(m => m.pageType).indexOf(params?.type)] ?? ''
  console.log(bg)
  const bg_title = menuList.filter(f=>f.pageRoute === 'Product').map(m => m.bg_title)[menuList.filter(f=>f.pageRoute === 'Product').map(m => m.pageType).indexOf(params?.type)] ?? ''
  const bg_content = menuList.filter(f=>f.pageRoute === 'Product').map(m => m.bg_content)[menuList.filter(f=>f.pageRoute === 'Product').map(m => m.pageType).indexOf(params?.type)] ?? ''
  const title = menuList.filter(f=>f.pageRoute === 'Product').map(m => m.title)[menuList.filter(f=>f.pageRoute === 'Product').map(m => m.pageType).indexOf(params?.type)] ?? ''

  let [plat, setPlat] = useState(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
  // 页面配置
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth) // 页面宽度
  useEffect(() => { // 页面宽度变化
    flexibleWidth()
    setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    setWindowWidth(document.documentElement.clientWidth)
    window.addEventListener('resize', () => {
      flexibleWidth()
      setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      setWindowWidth(document.documentElement.clientWidth)
    }, true)
  }, [])
  useEffect(() => {
    dispatch(setMenuIndex(menuList.map(m => m.title).indexOf(title)))
  }, [location])

  return (
    <>
    <Head
      title={title+'详情-'+commonConfig?.web_title}
      meta={[
        { "name": "keywords", "content": commonConfig?.web_keywords ?? '关键词' },
        { "name": "description", "content": commonConfig?.web_description ?? '描述内容' },
        { "property": "og:title", "content": commonConfig?.web_title ?? '标题' },
        { "property": "og:image", "content": commonConfig?.web_icon ?? '/favicon.ico' }
      ]}
      link={[
        {"rel": "shortcut icon", "href": "/favicon.ico", "type": "image/x-icon"},
      ]}
    />
    <div className='product_details'>
      {
        !plat && windowWidth>=821 ?
          <>
            <Header />
            <PDSection bg={bg} bg_title={bg_title} bg_content={bg_content} params={params} />
          </> :
          <>
            <HeaderMobile />
            <PDSectionMobile bg={bg} bg_title={bg_title} bg_content={bg_content} params={params} />
          </>
      }
    </div>
    </>
  )
}

export default ProductDetails